import React from 'react';
import Chart from 'react-apexcharts';
import { parseHTML } from '../utils/util';

type Props = {
  data: any;
  organizationData: [];
};

const AuditPieChart = (props: Props) => {

  // Define the status mapping
  const statusMapping = [
    { id: 0, name: "Fully compliant" },
    { id: 1, name: "Major non compliant" },
    { id: 2, name: "Minor non compliant" },
    { id: 3, name: "Observations" },
    { id: 4, name: "Suggestions" },
    { id: 5, name: "Comment" },
    { id: 6, name: "Not applicable" },
    { id: 7, name: "On hold" },
  ];

  // Map the statuses and count occurrences
  const statusCounts = statusMapping.reduce((acc: any, status) => {
    acc[status.name] = 0; // Initialize all counts to 0
    return acc;
  }, {});

  // Process the data to increment counts
  (props.data).forEach((audit: any) => {
    const plainStatus = parseHTML(audit.overallStatus); // Extract plain text
    const mappedStatus = statusMapping.find(
      (status) => status.name.toLowerCase() === plainStatus.toLowerCase()
    );
    if (mappedStatus) {
      statusCounts[mappedStatus.name] += 1;
    }
  });

  // Extract chart series and labels
  const pieChartLabels = Object.keys(statusCounts);
  const pieChartSeries: any = Object.values(statusCounts);

  // Apex chart options
  const pieChartOptions: any = {
    chart: {
      type: 'pie',
    },
    labels: pieChartLabels,
    title: {
      text: 'Overall Audit Status Distribution',
      align: 'center',
    },
    colors: ['#4caf50', '#f44336', '#ff9800', '#2196f3', '#9c27b0', '#607d8b'],
    legend: {
      position: 'bottom',
    },
  };

  // // Group data by organization and status
  // const groupedData: any = {};
  // props.data.forEach((audit: any) => {
  //   const plainStatus = parseHTML(audit.overallStatus);
  //   const organization = audit.organizationName || 'Unknown'; // Fallback if no organization name
  //   const mappedStatus = statusMapping.find(
  //     (status) => status.name.toLowerCase() === plainStatus.toLowerCase()
  //   );

  //   if (!groupedData[organization]) {
  //     groupedData[organization] = statusMapping.reduce((acc: any, status) => {
  //       acc[status.name] = 0;
  //       return acc;
  //     }, {});
  //   }

  //   if (mappedStatus) {
  //     groupedData[organization][mappedStatus.name] += 1;
  //   }
  // });

  // // Prepare data for the bar chart
  // const categories = Object.keys(groupedData);
  // const series = statusMapping.map((status) => ({
  //   name: status.name,
  //   data: categories.map((org) => groupedData[org][status.name]),
  // }));

  // // Chart options
  // const chartOptions: any = {
  //   chart: {
  //     type: 'bar',
  //     stacked: true,
  //     toolbar: {
  //       show: true,
  //     },
  //   },
  //   title: {
  //     text: 'Audit Status by Organization',
  //     align: 'center',
  //   },
  //   xaxis: {
  //     categories,
  //     title: {
  //       text: 'Organizations',
  //     },
  //   },
  //   yaxis: {
  //     title: {
  //       text: 'Count',
  //     },
  //   },
  //   legend: {
  //     position: 'bottom',
  //   },
  //   colors: ['#4caf50', '#f44336', '#ff9800', '#2196f3', '#9c27b0', '#607d8b', '#00bcd4', '#795548'],
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: '60%',
  //     },
  //   },
  // };

  // // Group audit data by parent organization
  // const groupedDataBar = (props.data).reduce((acc: any, audit: any) => {
  //   const { organizationName, overallStatus } = audit;
  //   acc[organizationName] = acc[organizationName] || [];
  //   acc[organizationName].push(overallStatus);
  //   return acc;
  // }, {});

  //  // Prepare chart data
  //  const parentLabels = Object.keys(groupedDataBar); // Parent organization names
  //  const seriesData: any[] = statusMapping.map(() => []); // Initialize series for each status

  //  parentLabels.forEach((parent) => {
  //    // Count statuses for each parent
  //    const statusCounts = statusMapping.reduce((acc: any, status: any) => {
  //      acc[status] = 0;
  //      return acc;
  //    }, {});

  //    groupedDataBar[parent].forEach((status: string) => {
  //      if (statusCounts[status] !== undefined) {
  //        statusCounts[status]++;
  //      }
  //    });

  //    // Populate series data for this parent
  //    statusMapping.forEach((status: any, index) => {
  //      seriesData[index].push(statusCounts[status]);
  //    });
  //  });

  //  // Chart series
  //  const chartSeries: any = statusMapping.map((status, index) => ({
  //    name: status,
  //    data: seriesData[index],
  //  }));

  //  // ApexCharts options
  //  const chartOptionsBar: any = {
  //    chart: {
  //      type: "bar",
  //      stacked: true,
  //    },
  //    title: {
  //      text: "Dependent Organization Audit Statuses by Parent",
  //      align: "center",
  //    },
  //    xaxis: {
  //      categories: parentLabels,
  //      title: {
  //        text: "Parent Organizations",
  //      },
  //    },
  //    yaxis: {
  //      title: {
  //        text: "Number of Dependent Organizations",
  //      },
  //    },
  //    legend: {
  //      position: "bottom",
  //    },
  //    plotOptions: {
  //      bar: {
  //        horizontal: false,
  //      },
  //    },
  //    colors: [
  //      "#4caf50",
  //      "#f44336",
  //      "#ff9800",
  //      "#2196f3",
  //      "#9c27b0",
  //      "#607d8b",
  //      "#e91e63",
  //      "#3f51b5",
  //    ],
  //  };

  // Step 1: Combine data and filter dependent organizations
  const combinedData = (props.organizationData)
    .map((org: any) => {
      // Find the related audit record
      const relatedAudit = (props.data).find(
        (audit: any) => audit.organizationName === org.name
      );

      return {
        orgName: org.name,
        orgType: org.organizationType,
        parentOrgName: org.dependentOrgCode,
        isAudited: relatedAudit ? "true" : "false",
        overallStatus: relatedAudit ? relatedAudit.overallStatus : "Not Audited",
        auditId: relatedAudit ? relatedAudit.id : null
      };
    })
    .filter((org) => org.orgType === "dependent");

  // Step 2: Prepare graph data
  const graphData = combinedData.map((org) => ({
    label: org.orgName,
    status: org.overallStatus,
    audited: org.isAudited
  }));

  // Output the combined and graph data
  console.log("Combined Data:", combinedData);
  console.log("Graph Data:", graphData);

  const dependentOrgs = (props.organizationData).filter((org: any) => org.organizationType === "dependent");

  // Step 2: Map audit data to organizations
  const multiBarData = dependentOrgs.map((org: any) => {
    const orgAudits = props.data.filter((audit: any) => audit.organizationId === org.id);

    // Example: Count audits by type
    const auditCountsByType = orgAudits.reduce((acc: any, audit: any) => {
      acc[audit.auditType] = (acc[audit.auditType] || 0) + 1;
      return acc;
    }, {});

    return {
      organizationName: org.name,
      auditsByType: auditCountsByType,
    };
  });

  // Step 3: Prepare data for the graph
  const categories = multiBarData.map(data => data.organizationName);
  const series = Object.keys(multiBarData[0]?.auditsByType || {}).map(type => ({
    name: type,
    data: multiBarData.map(data => data.auditsByType[type] || 0),
  }));

  console.log("Graph Categories:", categories);
  console.log("Graph Series:", series);
  
  return (
    <div>

      <Chart options={pieChartOptions} series={pieChartSeries} type="pie" height={350} />
      {/* <Chart options={chartOptions} series={series} type="bar" height={400} /> */}
      {/* <Chart options={chartOptionsBar} series={chartSeries} type="bar" height={400} />; */}
    </div>
  )

};

export default AuditPieChart;